.reactserverselect__control {
  border: 1px solid theme('colors.gray.500') !important;
  border-radius: 0 !important;
  padding: 8px;
  height: 56px;
  font-family: theme('fontFamily.titillium') !important;
  cursor: text !important;
  box-shadow: theme('boxShadow.md');
}

.reactserverselect__control--is-focused {
  box-shadow: theme('boxShadow.input') !important;
}

.reactserverselect__option--is-selected {
  background-color: theme('colors.purple.DEFAULT') !important;
  color: white !important;
}

.reactserverselect__value-container--is-multi {
  flex-direction: row !important;
  flex-wrap: nowrap !important;
}

.reactserverselect__multi-value {
  background-color: theme('colors.purple.DEFAULT') !important;
  padding: 6px 12px !important;
  display: flex !important;
  color: white !important;
  align-items: center;
}

.reactserverselect__single-value {
  font-family: theme('fontFamily.titillium');
  color: #000000 !important;
}

.reactserverselect__multi-value__label {
  font-family: theme('fontFamily.titillium');
  padding: 0 !important;
  color: white !important;
  font-weight: bold !important;
  max-width: 7rem;
}

.reactserverselect__value-container {
  padding-right: 0px !important;
}

.reactserverselect__input-container input[type='text']:focus {
  box-shadow: none !important;
}

.reactserverselect__multi-value__remove {
  border-radius: 25px !important;
  background: white;
  padding: 4px !important;
  margin-left: 6px;
}

.reactserverselect__multi-value__remove:hover {
  background-color: white !important;
}

.reactserverselect__input-container {
  margin: 0 !important;
  padding: 0 !important;
  color: #000000 !important;
}

.reactserverselect__multi-value__remove > svg {
  color: theme('colors.purple.DEFAULT');
  font-size: 1rem;
  width: 16px;
  height: 16px;
}

.reactserverselect__menu {
  border: 1px solid theme('colors.gray.500');
  border-radius: 0px !important;
}

.reactserverselect__menu > div > div {
  background-color: white;
}

.reactserverselect__menu > div > div:active {
  background-color: theme('colors.purple.500');
  color: white;
}

.reactserverselect__option:hover {
  background-color: theme('colors.purple.100');
  color: black;
}

.reactserverselect__clear-indicator > svg {
  display: none;
}

.reactserverselect__clear-indicator::after {
  cursor: pointer;
  border-radius: 50%;
  font-size: 24px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: '×';
  color: #c0c0c0 !important;
}

.reactserverselect__placeholder {
  @apply truncate;
}

@media only screen and (min-width: 640px) {
  .reactselect__control {
    font-size: 1.125rem !important;
  }

  .reactserverselect__single-value {
    font-size: 1.125rem !important;
  }

  .reactserverselect__multi-value__label {
    font-size: 1.125rem !important;
  }

  .reactserverselect__placeholder {
    font-size: 1.125rem !important;
    line-height: 1rem;
  }

  .reactserverselect__input-container {
    font-size: 1.125rem !important;
  }
}
