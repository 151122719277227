@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Regular.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'TitilliumWeb-Bold';
  src: url('./assets/fonts/TitilliumWeb-Bold.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'TitilliumWeb-SemiBold';
  src: url('./assets/fonts/TitilliumWeb-SemiBold.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'TitilliumWeb-Regular';
  src: url('./assets/fonts/TitilliumWeb-Regular.ttf');
  font-display: swap;
}

@layer components {
  .gray-button {
    @apply w-full py-4 px-[45px] shadow-sm bg-gray-100 text-medium sm:w-auto;
  }

  body {
    @apply font-titillium;
  }

  .yellow-button {
    @apply font-titilliumSemiBold rounded sm:py-[13px] py-[8px] sm:px-[25px] px-[15px] shadow-sm bg-yellow-500 text-black sm:w-auto h-fit text-center lg:w-48 w-32 sm:text-xl text-sm min-w-fit outline-none hover:bg-yellow-300;
  }

  .title {
    @apply font-titilliumBold sm:text-3xl lg:text-4xl text-2xl;
  }

  .subtitle {
    @apply font-titilliumBold sm:text-xl lg:text-2xl text-lg;
  }

  .body-text {
    @apply sm:text-xl text-sm sm:!leading-9 !leading-6;
  }

  .article {
    @apply sm:text-base text-sm text-gray-500 break-word;
  }

  .card-text-overflow {
    @apply text-ellipsis overflow-hidden break-word;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .card-title-overflow {
    @apply text-ellipsis overflow-hidden break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .virtuso-grid-list {
    @apply grid lg:grid-cols-cards-desktop md:grid-cols-cards-tablet grid-cols-cards-mobile gap-5 sm:gap-8;
  }

  .menu-title {
    @apply font-titilliumBold md:text-lg text-sm text-gray-900 leading-3 cursor-pointer whitespace-nowrap;
  }

  .side-menu-title {
    @apply font-titilliumBold text-lg text-gray-900 leading-3;
  }

  .footer-title {
    @apply font-titilliumBold text-base text-yellow;
  }

  .footer-text {
    @apply font-titillium text-base text-gray-300;
  }

  .footer-url {
    @apply footer-text hover:underline cursor-pointer;
  }

  .show-more-text {
    @apply text-blue cursor-pointer underline;
  }

  .break-word {
    word-break: break-word;
  }

  .range-clear-button::after {
    background-color: transparent !important;
    color: #c0c0c0 !important;
    height: 20px !important;
    width: 20px !important;
    font-size: 20px !important;
  }

  .datepicker-clear-button {
    padding: 0px !important;
  }

  .datepicker-clear-button::after {
    background-color: transparent !important;
    color: #c0c0c0 !important;
    font-size: 24px !important;
    padding: 0 16px 0 0 !important;
  }

  a {
    @apply text-purple;
  }

  .wrapper {
    @apply sm:w-[80%] w-[90%] max-w-screen-xl sm:py-20 py-10 flex flex-col sm:gap-8 gap-5 justify-items-center mx-auto;
  }
}

.content {
  /* Give the content enought white space so the footer is at the bottom */
  min-height: calc(100vh - 11rem - 13rem);
}
